.home-news {
  width: 100%;
  display:flex;
  flex-direction: column;
  position: relative;
  height: 900px;
  z-index: 1;
}

.home-news .home-news-gallery {
  height: 717px;
  position:relative;
  z-index: -1;
  overflow: hidden;
}
.home-news .home-news-gallery .home-news-slide{
 position: absolute;
 width: 100%;
 height: 100%;
}
.home-news .home-news-gallery .home-news-slide a::after {
  content:"";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(49,49,49,1) 0%, rgba(49,49,49,0) 30%);

}
.home-news .home-news-gallery .home-news-slide:not(:first-child) {
  transform: translateX(-100%);
}
.home-news .home-news-gallery .home-news-slide a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  filter: grayscale(1);
  transition: transform linear 0.5s;
}
.home-news .home-news-gallery .home-news-slide a:hover img {
  transform: scale(1.1);
}
.home-news .home-news-title {
  background-color: var(--main-darkgrey);
  display:flex;
  padding-left: 50%;
  height: 184px;
  flex-direction:column;
  padding-top: 35px;
  padding-bottom:35px;

}
.home-news .home-news-title .home-news-title-container {
  flex-basis: calc(100% - 36px);
  flex-grow:0;
  flex-shrink:0;
  max-width: 720px;
  width: 100%;
  padding-right: 74px;
  position: relative;
  overflow:hidden;
  display:flex;
  flex-direction:column;
}
.home-news .home-news-title .home-news-title-container .home-news-slide-title {
  position: absolute;
  top:0;
  left: 50px;
  width: calc(100% - 74px - 50px);
  flex-basis: 100%;
  height: 100%;
    overflow:hidden;
    display:flex;
    align-items: center;
}
.home-news .home-news-title .home-news-title-container .home-news-slide-title:not(:first-child) {
  transform: translateY(-100%);
}
.home-news .home-news-title .home-news-title-container .home-news-slide-title h3 a {
 color: #fff;
 font-weight: 600;
 transition: color ease 0.2s;
}
.home-news .home-news-title .home-news-title-container .home-news-slide-title h3 a:hover {
  color: var(--main-darkred);
}
.home-news .home-news-title .home-news-title-nav {
  max-width: 720px;
  padding-left: 50px;
}
.home-news .home-news-title .home-news-slide-index {
  position: absolute;
  top: calc(50% - 28px);
  right: 10px;
  margin: 0;
  padding: 0;
}
.home-news .home-news-title .home-news-slide-index li {
  list-style-type: none;
  /* test */
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-top: 5px;
  margin-bottom: 5px;
}
.home-news .home-news-title .home-news-slide-index li.active {
  background-color: #fff;
}
/*
.home-news .home-news-title .home-news-slide-index li a {
  display:block;
  width: 9px;
  height: 9px;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-top: 5px;
  margin-bottom: 5px;
}
.home-news .home-news-title .home-news-slide-index li a:hover,
.home-news .home-news-title .home-news-slide-index li a.active {
  background-color: #fff;
}*/
.home-red-square {
  max-width: 720px;
  width:50%;
  background-color: var(--main-red);
  /*min-height: 520px;*/
  position: absolute;
  right: calc(100vw / 2);
  bottom: -110px;
  z-index: 1;
  padding: 83px 110px 83px 110px;
  overflow: hidden;
}
.home-red-square h2 {
  font-size: 79px;
  line-height: 87px;
  color: #fff;
  margin: 0px;
}
.home-red-square p {
  color: var(--main-darkred);
  font-size: 30px;
  margin-bottom: 0px;
}
.home-red-square a {
  color: #fff;
  text-decoration: underline;
  font-size: 16px;
}
.home-red-square a:hover {
  font-weight:bold;
}
.under-news .icon-container {
  display: flex;
  justify-content: space-between;
  margin-top: 210px;
}
.under-news .icon-container .icon {
  flex-basis: 25%;
  flex-shrink: 1;
  flex-grow:1;
  margin-left: 22px;
  margin-right: 22px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.under-news .icon-container .icon .icon-img {
  width: 184px;
  height: 184px;
  background-color: #eeeff3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  border-radius: 50%;
  overflow: hidden;
}
.under-news .icon-container .icon a .icon-img img {
  filter:brightness(0);
  transition: all ease 0.2s;
  width: auto;
  height: auto;
}
.under-news .icon-container .icon a:hover .icon-img img {
  filter:brightness(1);
}
.under-news .icon-container .icon p {
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 13px;
  letter-spacing: 1.52px;
  text-transform: uppercase;
  width:100%;
}
.under-news .icon-container .icon a p {
  color: var(--main-darkgrey);
  transition: all ease 0.2s

}
.under-news .icon-container .icon a:hover p {
  color: var(--main-red);
}
.home-slider {
  width: 100%;
  height: 600px;
  max-width: 1200px;
  display:flex;
  margin-top:100px;
  overflow: hidden;
}
.home-slider .home-slider-container {
  flex-basis: 50%;
  flex-grow:0;
  flex-shrink:0;
  position: relative;
  overflow:hidden;
}
.home-slider .home-slider-container .home-slider-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left: 0;
}
.home-slider .home-slider-container .home-slider-slide:not(:first-child){
  opacity: 0;
}
.home-slider .home-slider-container .home-slider-slide img {
  width:100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  filter: grayscale(1);
}
.home-slider .home-sliderTxt-container {
  flex-basis: 50%;
  flex-grow:0;
  flex-shrink:0;
  position: relative;
  overflow:hidden;
  display:flex;
  flex-direction: column;
  border: 16px solid var(--main-darkgrey);
  text-align: center;
  justify-content: flex-start;
  align-items: center;
    background-color: #fff;
}
.home-slider .home-sliderTxt-container a {
  color: #000;
  text-decoration: underline;
}
.home-slider .home-sliderTxt-container .home-sliderTxt-container-content {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 80px);
  width: 100%;
  height: calc(100% - 80px);
  overflow:hidden;
  position: relative;

}
.home-slider .home-sliderTxt-container .home-sliderTxt-container-content .home-sliderTxt-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 110px 45px 0px 45px;

}
.home-slider .home-sliderTxt-container .home-sliderTxt-container-content .home-sliderTxt-slide:not(:first-child) {
  opacity: 0;
  transform: translateY(-100%);
}
.home-slider .home-sliderTxt-container .home-sliderTxt-container-content .home-sliderTxt-slide h1 {
  font-weight:normal;
  font-size: 13px;
  letter-spacing: 1.52px;
  text-transform: uppercase;
  margin: 0px 0px 5px 0px;
}
.home-slider .home-sliderTxt-container .home-sliderTxt-container-content .home-sliderTxt-slide h2 {
  font-size: 42px;
  line-height: 44px;
  margin: 0px 0px 35px 0px;
}
.home-slider .home-sliderTxt-container .home-sliderTxt-container-content .home-sliderTxt-slide p {
  margin: 0px 0px 40px 0px;
}
.home-slider .home-sliderTxt-container .home-sliderTxt-container-content .home-sliderTxt-slide p a {
  color: var(--main-darkgrey);
  text-decoration: underline;
}
.home-slider .home-sliderTxt-container .home-sliderTxt-container-content .home-sliderTxt-slide p a:hover {
  font-weight: bold;
  color: var(--main-red);
}
.home-slider .home-sliderTxt-container .home-slider-navbar {
  width: 100%;
  position: absolute;
  bottom:44px;
  text-align:left;
}
.under-slider {
  max-width: 1200px;
  width:100%;
  margin-top: 40px;
  position: relative;
  background-color: #eeeff3;
  padding:115px;
}
.under-slider .under-slider-content {
  border: 1px solid var(--main-grey);
  width:100%;
  height: 100%;
  padding:70px 45% 70px 70px;
  position:relative;
}
.under-slider .under-slider-content h1 {
  font-size: 42px;
  line-height: 44px;
  color: var(--main-red);
  margin: 0px 0px 23px 0px;
}
.under-slider .under-slider-content p a {
  text-decoration: underline;
}
.under-slider .under-slider-content p a:hover {
  font-weight: bold;
}
.under-slider .under-slider-content .under-slider-image {
  position: absolute;
  bottom: 0px;
  right: 40px;
  line-height: 0px;
}
.settori-intervento {
  width: 100%;
  background-image: url('images/background-line-red.svg');
  background-size: 240px 10px;
  background-repeat: repeat;
  background-position: center;
  margin-top: -95px;
}
.settori-intervento .settori-intervento-container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  margin-bottom: 156px;
  text-align: center;
}
.settori-intervento .settori-intervento-container h2 {
  color: #fff;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 3.3px;
  text-transform: uppercase;
  margin-bottom:35px;
}
.settori-intervento-container .settori-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.settori-intervento-container .settori-grid .settore {
  flex-basis: calc(50% - 7px);
  background-color: var(--main-darkgrey);
  margin-bottom:14px;
  position: relative;
  height: 530px;
  text-align: center;
  overflow:hidden;
}
.settori-intervento-container .settori-grid .settore a {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}
.settori-intervento-container .settori-grid .settore a::before {
  content:"";
  display: block;
  width: 100%;
  height: 0%;
  position: relative;
  top:0;
  left:0;
  background-color: #fff;
  transition: height ease 0.2s;
}
.settori-intervento-container .settori-grid .settore a:hover::before {

  height: 100%;

}

.settori-intervento-container .settori-grid .settore .image {
  position:absolute;
  bottom:0;
  left: 0;
  width: 100%;
  height: auto;
    line-height: 0px;
}
.settori-intervento-container .settori-grid .settore .image img {
  width: 100%;
  height: auto;
  filter: grayscale(1);
  object-position: 50% 50%;
  object-fit: cover;
}
.settori-intervento-container .settori-grid .settore .text {
  position: absolute;
  top: 75px;
  left:0;
  width: 100%;
  font-size: 19px;
  line-height: 24px;
  text-transform:uppercase;
  color:#fff;
  transition: color ease 0.2s;
}
.settori-intervento-container .settori-grid .settore a:hover .text {
  color: var(--main-darkgrey);
}
.home-clienti {
  width: 100%;
  max-width: 1200px;
  margin: 75px auto 170px auto;
}
.home-clienti h2 {
  font-size: 16px;
  letter-spacing: 3.3px;
  font-weight: normal;
  margin: 0px 0px 52px 0px;
  text-align: center;
  text-transform: uppercase;
}
.home-clienti .area-loghi {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
}
.home-clienti .logo-container {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  justify-content: center;
  transform: translateX(0%);
  transition: all ease 0.2s;
}
.home-clienti .logo-container .logo {
  flex-basis: 25%;
  padding-left: 47px;
  padding-right: 47px;
  flex-shrink: 0;
  flex-grow: 1;
  text-align: center;

}
.home-clienti .logo-container.move {
  transform: translateX(-100%);
}
.home-clienti ul {
  margin: 10px 0px 10px 0px;
  padding:0px;
  text-align: center
}
.home-clienti ul li {
  display: inline;
  list-style-type: none;
  margin: 0px 4px;
}
.home-clienti ul li a {
  display: inline-block;
  width: 9px;
  height: 9px;
  border: 1px solid #000;
  border-radius: 50%;
}
.home-clienti ul li a:hover,
 .home-clienti ul li a.active{
  background-color: #000;
}
/*
.home-clienti .logo-container {
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content: center;
}
.home-clienti .logo-container .logo {
  flex-basis: calc(25% - 47px);
  flex-grow:0;
  flex-shrink: 0;
  margin-left: 47px;
  margin-right: 47px;
  display:flex;
  justify-content: center;
  align-items: center;
}*/
.home-contatti {
  width: 100%;
}
.home-contatti .container {
  max-width: 1200px;
  display:flex;
}
.home-contatti .container > div {
  flex-basis: 50%;
  flex-grow:1;
  flex-shrink:1;
}
.home-contatti .container .contatti-map {
  margin-top: -68px;
}
.home-contatti #googleMaps {
  height: 605px;
}
.home-contatti .contatti-dx {
  padding: 100px 0px 0px 120px;
}
.home-contatti .contatti-dx h2 {
  color: #fff;
  font-size: 49px;
  line-height: 57px;
  margin-bottom:35px;
  margin-top:0px;
}
.home-contatti .contatti-dx address,
.home-contatti .contatti-dx p {
  font-style: normal;
  color: #fff;
  font-size:20px;
  line-height: 34px;
  padding-left: 37px;

  position:relative;
}
.home-contatti .contatti-dx p a {
  color: #fff;
  text-decoration: underline;
}
.home-contatti .contatti-dx address {
    margin-bottom: 50px;
}
.home-contatti .contatti-dx address::after{
    content: "";
    display: block;
    width: 73px;
    height: 1px;
    background-color: #fff;
    margin-top: 40px;
}
.home-contatti .contatti-dx address::before,
.home-contatti .contatti-dx p::before{
  content: "";
    display: block;
    position: absolute;
    width: 17px;
    height: 30px;
    background-image: url('images/pingrey-icon-white.svg');
    background-size: 17px auto;
    background-repeat: no-repeat;
    background-position: top left;
    top: 5px;
    left: 0px;

}
.home-contatti .contatti-dx p.telContatti::before {
  background-image: url("images/tel-icon-white.svg");
  background-size: 15px auto;
}
.home-contatti .contatti-dx p.faxContatti::before {
  background-image: url("images/fax-icon-white.svg");
  top: 8px;
}
.home-contatti .contatti-dx p.mailContatti::before {
  background-image: url("images/mailwhite-icon.svg");
  top: 10px;
}

.home-news .home-news-title .home-news-slide-index,
.home-news .home-news-gallery .home-realizzazioni-slide-index  {
  position: absolute;
  top: calc(50% - 28px);
  right: 10px;
  margin: 0;
  padding: 0;
}
.home-news .home-news-title .home-news-slide-index li
 {
  list-style-type: none;
  /* test
  width: 9px;
  height: 9px;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-top: 5px;
  margin-bottom: 5px;*/
}
.home-news .home-news-gallery .home-realizzazioni-slide-index li {
  list-style-type: none;
}
/*.home-news .home-news-title .home-news-slide-index li a.active {
  background-color: #fff;
}*/
.home-news .home-news-title .home-news-slide-index li a,
.home-news .home-news-gallery .home-realizzazioni-slide-index li a  {
  display:block;
  width: 9px;
  height: 9px;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-top: 5px;
  margin-bottom: 5px;
}
.home-news .home-news-title .home-news-slide-index li a:hover,
.home-news .home-news-title .home-news-slide-index li a.active,
.home-news .home-news-gallery .home-realizzazioni-slide-index li a:hover,
.home-news .home-news-gallery .home-realizzazioni-slide-index li a.active {
  background-color: #fff;
  cursor: pointer;
}
