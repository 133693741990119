
.container-404 {
  width: 100vw;
  height: 100vh;
  background-image: url("images/chiese.jpg");
  background-repeat:no-repeat;
  background-position: right bottom;
}
.container-404-2 {
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 65px 0px 65px;
}
.container-404 h1 {
  font-size: 104px;
  font-weight: bold;
  font-family: var(--main-font);
  margin-top:321px;
  margin-bottom: 0px;
  line-height: 104px;
}
.container-404 p {
  font-size: 34px;
  line-height: 50px;
  font-family: var(--main-fornt);
  margin-top: 90px;
  margin-bottom: 0px;
  color: var(--main-darkgrey);
}
.container-404 ul {
  margin: 60px 0px 0px 0px;
  padding: 0px ;
}
.container-404 ul li {
  list-style-type: none;
  display: inline-block;
}
.container-404 ul li:first-child {
  margin-right: 30px;
}
