#logo-mobile-svg {
  display: none;
}
#site-header {
  max-width: 1440px;
  width: 100%;
  position: absolute;
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px 65px 0px 65px;
  align-items: center;
  z-index:2;
}
#site-header header,
#site-header .menu,
#site-header .mail {
  flex-grow: 0;
  flex-shrink: 0;
}
#site-header header h1 {
  margin:0px;
}
#site-header .preventivo {
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: 26px;
  margin-right: 26px;
  text-align: right;
}
#site-header .menu a {
  display: inline-block;
  width: 63px;
  height: 63px;
  background-color: rgba(255,255,255,1);
  border-radius: 50%;
  position: relative;
  z-index: 1000;
  transition: background-color ease 0.2s;
}
#site-header .menu a::before {
  display: block;
  content: "";
  width: 28px;
  height: 3px;
  background-color: var(--main-red);
  top: calc(50% - 6px);
  left: calc(50% - 14px);
  position: absolute;
  transform-origin:center;
  transition: top ease 0.2s  0.2s, transform ease 0.2s;
}
#site-header .menu a::after {
  display: block;
  content: "";
  width: 28px;
  height: 3px;
  background-color: var(--main-red);
  top: calc(50% + 6px);
  left: calc(50% - 14px);
  position: absolute;
  transform-origin:center;
  transition: top ease 0.2s 0.2s, transform ease 0.2s ;
}
body.menu-aperto #site-header .menu a {
  background-color: rgba(255,255,255,0);
}
body.menu-aperto #site-header .menu a::after {
  transform: rotate(45deg);
  top: 50%;
  transition: top ease 0.2s, transform ease 0.2s 0.2s;
  background-color: #fff;
}
body.menu-aperto #site-header .menu a::before {
  transform: rotate(-45deg);
  top: 50%;
  transition: top ease 0.2s, transform ease 0.2s 0.2s;
  background-color: #fff;
}
html.menu-aperto,
body.menu-aperto {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position:relative;
}
body nav.main-menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background-color: var(--main-red);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  transform: scaleY(0);
  opacity: 0;
  transform-origin: top center;
  transition: transform ease 0.2s 0.2s, opacity ease 0.2s;
  z-index: 999;
}
body.menu-aperto nav.main-menu {
  transform: scale(1);
  opacity: 1;
  transition: transform ease 0.2s, opacity ease 0.2s;
}
body nav.main-menu > div > div {
  opacity: 0;
  transform:translateX(50px);
  transition: all ease 0.2s;
}
body nav.main-menu > div:nth-child(1) > div {
  transform:translateX(-50px);
}
body.menu-aperto nav.main-menu > div > div  {
  opacity: 1;
  transform:translateX(0px);
  transition: all ease 0.2s 0.2s;
}

body nav.main-menu > div {
  flex-basis: 50%;
  flex-grow:1;
  flex-shrink: 1;
  display:flex;
}
body nav.main-menu > div:nth-child(1) {
  background-color: #ffff;
  overflow: hidden;
  justify-content: flex-end;
}
body nav.main-menu > div > div {
  max-width: 720px;
  flex-basis: 720px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
}
body nav.main-menu > div:nth-child(1) > div {
  max-width: none;
  flex-grow: 1;

}
body #site-header .language-switcher {
  text-align: right;
  margin-right: 26px;
}
body #site-header .language-switcher ul {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
body #site-header .language-switcher ul li {
  display: inline;
  list-style-type: none;
  color:  #fff;
  letter-spacing: 3px;
  font-weight: bold;
  font-size: 12px;
}
body #site-header  .language-switcher ul li:not(:last-child)::after {
  content: " |";
}
body #site-header .language-switcher ul li a {
  color: #ffff;
}
body #site-header .language-switcher ul li.current-lang a {
  color: #fff;
}
body #site-header  .language-switcher ul li a:hover {
  text-decoration: underline;
}
body nav.main-menu .main-menu-list {
  margin-top: 170px;
  flex-grow: 1;
  overflow-y: auto;
  margin-right: 10px;
}
body nav.main-menu .main-menu-list::-webkit-scrollbar {
  width: 6px;
}
body nav.main-menu .main-menu-list::-webkit-scrollbar-track {
  background: rgba(0,0,0,0);
}
body nav.main-menu .main-menu-list::-webkit-scrollbar-thumb {
  background: var(--main-darkred);
  border-radius: 3px 3px 3px 3px;
}

body nav.main-menu .main-menu-list > ul {
  margin: 0px 60px 0px ;
  padding: 0px;
  max-width: 600px;
}
body nav.main-menu > div > div > footer {
  margin-left: 60px;
  margin-right: 60px;
  margin-bottom: 6px;
  padding-top: 10px;
}
body nav.main-menu .main-menu-list ul li {
  list-style-type: none;
  font-weight: normal;
  font-size: 32px;
  line-height: 47px;
  margin-bottom: 40px;
  position: relative;
}
body nav.main-menu .main-menu-list ul li a {
  color: #fff;
}
body nav.main-menu .main-menu-list ul li.menu-item-has-children {
  display: inline-block;
  padding-right: 0px;
}/*
body nav.main-menu .main-menu-list ul li.menu-item-has-children > a::after {
  display: inline-block;
  content: "";
  padding-left: 20px;
  padding-right: 20px;
  background-image: url('images/menu-arrow.svg');
  background-repeat: no-repeat;
  background-position: center center;
  width: 14px;
  height: 10px;
  vertical-align: middle;
  transform-origin: center center;
  transform: rotate(180deg);
  transition: transform ease 0.2s;
}
body nav.main-menu .main-menu-list ul li.menu-item-has-children.open > a::after {
  transform: rotate(0deg);
}*/
body nav.main-menu .main-menu-list ul li.menu-item-has-children > span::after {
  display: inline-block;
  content: "";
  padding-left: 25px;
  padding-right: 25px;
  background-image: url('images/menu-arrow.svg');
  background-repeat: no-repeat;
  background-position: center center;
  width: 14px;
  height: 10px;
  vertical-align: middle;
  transform-origin: center center;
  transform: rotate(180deg);
  transition: transform ease 0.2s;
}
body nav.main-menu .main-menu-list ul li.menu-item-has-children.open > span::after  {
  transform: rotate(0deg);
}
body nav.main-menu .main-menu-list ul li > a:hover,
body nav.main-menu .main-menu-list ul li.open > a
 {
  color: var(--main-darkred);
}
body nav.main-menu .main-menu-list ul li ul {
  overflow: hidden;
  height: 0px;
  margin: 0px;
  padding: 0px;
  transition: height ease 0.2s;
}
body nav.main-menu .main-menu-list ul li ul li:first-child {
    padding-top: 30px;
}
body nav.main-menu .main-menu-list ul li ul li {
  margin-bottom: 25px;
  font-size: 27px;
  margin-left: 62px;
}

body nav.main-menu .main-menu-list ul li ul li a {
  font-weight: normal;
  color: var(--main-darkred);
}
body nav.main-menu .main-menu-list ul li ul li a:hover {
  color: #fff;
  font-weight: bold;
}
/*menu laterale custom category */
body nav.main-menu .realizzazioni-link {
  justify-content: space-between;
}
body nav.main-menu .realizzazioni-link ul {
  display:flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  flex-grow: 1;
  flex-shrink: 1;
}
body nav.main-menu .realizzazioni-link ul li {
  flex-grow: 1;
  flex-shrink: 1;
  list-style-type: none;
  position: relative;
}
body nav.main-menu .realizzazioni-link ul li a {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  color: var(--main-red);
  font-size: 16px;
  letter-spacing: 3px;
  text-transform: uppercase;
  transition: color ease 0.1s 0.05s;
}
body nav.main-menu .realizzazioni-link ul li a img {
  object-fit:cover;
  width:auto;
  height: 100%;
  filter: grayscale(100%);
  max-width: 50%;
  object-position: center right;
}
body nav.main-menu .realizzazioni-link ul li a span {
/*  position: absolute;
  min-height: 44px;
  top: calc(50% - 22px);
  left: calc(50%);
  max-width: 220px;*/
  height: 100%;
  position: absolute;
  top: 0;
  left: calc(50%);
  max-width: 50%;
  width: 100%;
  display: flex;
  align-items: center;

}
body nav.main-menu .realizzazioni-link ul li a:hover {
  color: #fff;
}
body nav.main-menu .realizzazioni-link ul li a::before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 0%;
  background-color: var(--main-darkgrey);
  transition: height ease 0.1s;
}
body nav.main-menu .realizzazioni-link ul li a:hover::before {
  height: 100%;
}
