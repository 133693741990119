.page-template-page_qualita .container.default-page div.wheel-container .animationCircle,
.page-template-page_qualita .container.default-page div.wheel-container .animationCircleSlider {
  flex-basis: 50%;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
  min-height: 680px;
  position: relative;
}
.page-template-page_qualita .animationCircleSlider .qualita-pulsanti {
  position: absolute;
  bottom: 90px;
  left: 75px;
}
.animationCircle ul {
  width: 570px;
  height: 570px;
  border: 2px solid #E1E1E1;
  border-radius: 50%;
  position: absolute;
  transform-origin: 50% 50%;
  padding: 0px;
  margin: 0px;
  top: 55px;
  left: calc(50% - 55px);
}

.animationCircle ul li {
  position: absolute;
  list-style: none;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: #fff;
  transform-origin: 50% 50%;

}
.animationCircle ul li svg {
  top: 0px;
  left: 0px;
  position:absolute;
}
.animationCircle ul li a {
  position: absolute;
  width: 70px;
  height: 70px;
  display: block;
  border-radius: 50%;
  top: 20px;
  left: 20px;
  background-color: #fff;
  transition: background-color 0.3s ease;
}
.animationCircle ul li a:hover,
.animationCircle ul li a.active {
  background-color: var(--main-darkgrey);
}/*
.animationCircleSlider {
  position: absolute;
  top: 100px;
  right:600px;
  background-color: red;
  width: 500px;
  height: 500px;
    overflow: hidden;
}*/

.animationCircleSlider .acSlide {
  position:absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  padding: 130px 0px 0px 75px;
  transition: tranform ease 0.5s;
}
.animationCircleSlider .acSlide:not(:first-child) {
  transform: translateX(100%);
}
.animationCircleSlider .acSlide h1{
  font-size: 13px;
  font-weight: normal;
  text-transform:  uppercase;
  margin: 0px 0px 18px 0px;
}
.animationCircleSlider .acSlide h2 {
  color: var(--main-darkgrey);
  font-size: 43px;
  line-height: 47px;
  margin: 0px 0px 35px 0px;
}
.animationCircle ul li a img {
  margin-top: calc(50% - 13.5px);
  margin-left: calc(50% - 13.5px);
  filter: invert(100%);
}
.animationCircle ul li a:hover img,
.animationCircle ul li a.active img{
  filter: invert(0%);
}
