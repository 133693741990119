/*
  Theme Name: Faggion_2019
  Theme URI: https://www.altramarca.net
  Author: MZ
  Description: Faggion Theme (altramarca)
  Version: 1.0
*/

* {
   box-sizing: border-box;
     text-decoration: none;
}
html.no-scroll{
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position:relative;
}
body {
  overflow-x:hidden;
}
 h1 {
  font-size: 22px;
  line-height: 24px;
  color: var(--main-darkgrey);
}

 p, ul {
  color: var(--main-grey);
  font-size: 16px;
  line-height: 28px;
}
 p strong {
  color: var(--main-darkgrey);

}
 p a {
  color: var(--main-red);
}
 p a:hover {
  text-decoration:underline;
}
:root {
  --main-red:   #cc4045;
  --main-darkred: #91161A;
  --main-darkgrey: #313131;
  --main-grey: #959595;

  --main-font: 'Graphik';
}
body {
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  margin: 0px;
  font-family: var(--main-font);
  font-size: 16px;
  line-height: 28px;
  background-image: url('images/background-line-grey.svg');
  background-size: 240px 10px;
  background-repeat: repeat;
  background-position: center;
}
body.overlay {
  overflow: hidden;
  width: 100vw;
  /*height: 100vh;*/
  position:relative;
}
body::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  background-color: rgba(0,0,0,0);
  position:fixed;
  top: 0;
  bottom: 0;
  z-index: 100;
  visibility: hidden;
  transition: background-color ease 0.1s, visibility linear 0.1s 0.1s, width linear 0.1s 0.1s, height linear 0.1s 0.1s;

}
body.overlay::before {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.8);
  visibility: visible;
  transition: background-color ease 0.1s;
}
body.overlay.loading::before {
  background-image: url('images/spinner-loading.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.full-width {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

}
.full-width.header {
  /*height: 640px;*/
  height: 460px;
}
.post-type-archive-realizzazioni .full-width.header {
  height: 460px;
}
.full-width.header.background-red {
  background-image: url('images/background-line-red.svg');
  background-size: 240px 10px;
  background-repeat: repeat;
  background-position: center;
}
.full-width.header.background-grey,
.background-grey.background-line {
  background-image: url('images/background-line-black.svg');
  background-size: 240px 10px;
  background-repeat: repeat;
  background-position: center;
}
.full-width header.container,
.post-type-archive-realizzazioni .full-width header.container {
  max-width: 1440px;
  width: 100%;
  /*margin-top: 250px;*/
  margin-top: 170px;
  text-align: center;
  color: #fff;
}
.full-width header.container h1,
.single-realizzazioni .full-width header.container p   {
  margin: 0px 0px 10px 0px;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
}
.full-width header.container h1 a {
  color: #fff;
  text-decoration: none;
  transition: color ease 0.1s;
}
.full-width header.container p  {
  color: #fff;
}
.full-width header.container h1 a:hover {
  color: var(--main-darkgrey);
}
.full-width header.container p,
.post-type-archive-realizzazioni .full-width header.container h1,
.single-realizzazioni .full-width header.container h1{
  margin: 0px;
  font-weight: bold;
  font-size: 89px;
  line-height: 73px;
}
.single-realizzazioni .full-width header.container h1{
  margin: 0px;
  font-weight: bold;
  font-size: 57px;
  line-height: 73px;
}
.single-realizzazioni .full-width header.container h1 {
  color: #fff;
}
.post-type-archive-realizzazioni .full-width header.container h1{
 color: #fff;
 text-transform:none;
}
.full-width.newsletter .container {
  text-align: center;
}
.full-width.newsletter .container p {
  margin: 33px 0px;
  font-size: 25px;
  color:#fff;
}
.full-width.newsletter .container p a {
  text-decoration: underline;
  font-weight: 600;

}
.full-width.newsletter.background-white .container p {
  color:var(--main-darkgrey);
}
.full-width.footer .container  {
  text-align: center;
  padding-top: 96px;
  padding-bottom: 65px;
}
.full-width.footer .container p {
    font-size: 16px;
    line-height: 28px;
    color: var(--main-grey);
}
.full-width.footer .container p a {
  color: var(--main-grey);
}

.full-width.footer .container svg #logo path {
  fill: #787878;
}
.full-width.footer .container .copyright {
  font-size: 14px;
  line-height: 15px;
  color: #494C50;
}
.container {
  max-width: 960px;
  width: 100%;
  margin-left:auto;
  margin-right: auto;
}
.category .push-up,
.single-realizzazioni .push-up {
  margin-top: -75px;
}
.copyright {
  font-size: 10px;
  line-height: 14px;
}
.zero-result {
  font-size: 24px;
  font-weight: bold;
  line-height: 26px;
  text-align: center;
  color: var(--main-darkgrey);
}
.single:not(.single-realizzazioni) #site-header svg #logo path,
.page-template-page_qualita-php #site-header svg #logo path,
.error404 #site-header svg #logo path{
  fill: var(--main-red);
}
.single:not(.single-realizzazioni) #site-header svg #scritta #lettere path,
.page-template-page_qualita-php #site-header svg #scritta #lettere path,
.error404 #site-header svg #scritta #lettere path {
  fill: var(--main-darkgrey);
}
.single:not(.single-realizzazioni) #site-header .rect,
 .page-template-page_qualita-php #site-header .rect,
 .error404 .rect{
  border: 1px solid var(--main-red);
  border-bottom: 6px solid var(--main-red);
}
.single:not(.single-realizzazioni) #site-header .white span,
.page-template-page_qualita-php #site-header .white span,
.error404  .white span    {
  color: var(--main-red);
  padding: 10px 29px 10px 29px;

}
.single:not(.single-realizzazioni) #site-header .white:hover span,
.page-template-page_qualita-php #site-header .white:hover span,
.error404  .white:hover span{
  color: #fff;
}
.single:not(.single-realizzazioni) #site-header .white::before{
  background-color: #fff;
}
.single:not(.single-realizzazioni) #site-header .white:hover::before,
.page-template-page_qualita-php #site-header .white:hover::before,
.error404 .white:hover::before{
  background-color: var(--main-red);
}

.single:not(.single-realizzazioni) article.container {
  margin-top: 250px;
}
.home-icon-servizi {
  background-image: url('images/home-icon-servizi.svg');
  background-size: 241px auto;
  background-repeat: no-repeat;
  background-position: calc(100% - 241px) center;
}
