.desktop-hide {
  display:none;
}
.mobile-hide {
  display:block;
}
.background-red {
  background-color: var(--main-red);
}
.background-grey {
  background-color: var(--main-darkgrey);
}
.background-black {
  background-color: #000;
}
.background-white {
  background-color: #fff;
}
.background-light-grey {
  background-color: #eeeff3;
}
.background-darkred {
  background-color: var(--main-darkred);
}
/* bottoni */
.text-grey {
  color: var(--main-darkgrey);
  transition: color ease 0.2s;
}
.text-grey:hover {
  color: var(--main-red);
}
.text-white {
  color: #fff;
  transition: color ease 0.2s;
}
.text-white:hover {
  color: var(--main-red);
}
.btn {
  font-family: var(--main-font);
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
}
.rect {
  display: inline-block;
  position: relative;
  width: auto;
  height: auto;
}
.white {
  background-color: #fff;
}
.rect:hover {
  text-decoration: none;
}
.white span {
    color: #000;
    position: relative;
    width: auto;
    height: auto;
    display: block;
    padding: 10px 32px 10px 32px;
    /*z-index: 1000;*/
    letter-spacing: 1.52px;
    transition: color ease 0.1s 0.05s;
}
.white::before {
  position:absolute;
  content: "";
  display:block;
  width:100%;
  height: 0%;
  background-color: var(--main-darkgrey);
  top:0px;
  left:0px;
  /*z-index: 999  ;*/
  transition: all ease 0.1s;
}

.white:hover span {
  color:#fff;
}
.white:hover::before {
  background-color: var(--main-darkgrey);
  height:100%;
}
.page .white:hover::before,
.home .white:hover::before {
  background-color: var(--main-red);
  height:100%;
}
.red {
  background-color: rgba(0,0,0,0);
  border: 1px solid #fff;
  border-bottom-width: 6px;
}
.red span {
    color: #fff;
    position: relative;
    width: auto;
    height: auto;
    display: block;
    padding: 10px 29px 10px 29px;
    /*z-index: 1000;*/
    letter-spacing: 1.52px;
    transition: color ease 0.1s 0.05s;
}
.red::before {
  position:absolute;
  content: "";
  display:block;
  width:100%;
  height: 0%;
  background-color: #fff;
  top:0px;
  left:0px;
  /*z-index: 999  ;*/
  transition: all ease 0.1s;
}

.red:hover span {
  color: var(--main-darkred);
}
.red:hover::before {
  height:100%;
}
.grey {
  background-color: rgba(0,0,0,0);
  border: 1px solid #fff;
  border-bottom-width: 6px;
}
.grey span {
    color: #fff;
    position: relative;
    width: auto;
    height: auto;
    display: block;
    padding: 10px 29px 10px 29px;
    /*z-index: 1000;*/
    letter-spacing: 1.52px;
    transition: color ease 0.1s 0.05s;
}
.grey::before {
  position:absolute;
  content: "";
  display:block;
  width:100%;
  height: 0%;
  background-color: #fff;
  top:0px;
  left:0px;
  /*z-index: 999  ;*/
  transition: all ease 0.1s;
}

.grey:hover span {
  color: var(--main-darkgrey);
}
.grey:hover::before {
  height:100%;
}
.darkgrey {
  background-color: var(--main-darkgrey);
  border: 1px solid var(--main-darkgrey);
  border-bottom-width: 6px;
}
.darkgrey span {
    color: #fff;
    position: relative;
    width: auto;
    height: auto;
    display: block;
    padding: 10px 29px 10px 29px;
    /*z-index: 1000;*/
    letter-spacing: 1.52px;
    transition: color ease 0.1s 0.05s;
}
.darkgrey::before {
  position:absolute;
  content: "";
  display:block;
  width:100%;
  height: 0%;
  background-color: #fff;
  top:0px;
  left:0px;
  /*z-index: 999  ;*/
  transition: all ease 0.1s;
}

.darkgrey:hover span {
  color: var(--main-darkgrey);
}
.darkgrey:hover::before {
  height:100%;
}
.underline {
  text-decoration: underline;
}
.relative .container,
.relative {
  position: relative;
  overflow: visible;
}
