.realizzazioni-submenu {
  display: flex;
  flex-direction: row;
  min-height: 137px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: visible;
}

.post-type-archive-realizzazioni .realizzazioni-submenu .container {
  max-width: 1370px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding-top: 32px;
  padding-bottom: 32px;
}
.post-type-archive-realizzazioni .search-panel .container {

  position: relative;
}
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-cat {
  flex-basis: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
}
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-tool {
  flex-basis: 189px;
  flex-shrink: 0;
  flex-grow:0;
  width: 188px;
}
.realizzazioni-submenu .submenu-panel {
  width: 100%;
  max-height: 0px; /* da sistemare */
  background-color: #fff;
  position: absolute;
  top: 105px;
  left: 0px;
  transition: max-height ease 0.2s;
  overflow: hidden;
    z-index: 1;
}
.realizzazioni-submenu .submenu-panel.open {
  max-height: 6000px; /* da sistemare */
  transition: max-height ease 1s;
}
.post-type-archive-realizzazioni .realizzazioni-submenu .submenu-panel .tag.container {
  padding: 0px;
}

.realizzazioni-submenu .submenu-panel .tag,
.realizzazioni-submenu .submenu-panel .map {
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  max-height: 0px;
  opacity: 0;  transition: opacity linear 0.2s, visibility linear 0s 0.2s, max-height linear 0s 0.2s;
}
.realizzazioni-submenu .submenu-panel .tag.open,
.realizzazioni-submenu .submenu-panel .map.open {
  opacity: 1;
  visibility: visible;
  overflow: hidden;
  max-height: 6000px;
  opacity: 1;
  transition: opacity linear 0.2s 0.1s, visibility linear 0s , max-height linear 0s ;


}
.map {
  padding: 55px 35px 55px 35px;
}
.map p.titlemap {
  color: #000;
  text-transform: uppercase;
  margin: 0px 0px 30px 0px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1.5px;

}
#mappRealizzazioni {
  min-height: 630px;
}
.realizzazioni-submenu .search-panel {
  width: 0%;
  height: 100%;
  background-color: var(--main-darkred);
  position: absolute;
  top: 0px;
  right: 0px;
  overflow: hidden;
  transition: width ease 0.2s, opacity ease 0.2s;
  opacity:0;
  display: flex;
  align-items: center;
}
.realizzazioni-submenu .search-panel.open {
  width: 100%;
  opacity: 1;
}
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-cat ul,
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-tool ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  height: 100%;
  align-items: center;

}
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-cat ul li:first-child {
  display:none;
}
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-cat ul li,
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-tool ul li {
  list-style-type: none;
  display: flex;
  text-align: center;
}
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-cat ul li:not(:last-child) {
  margin-right: 24px
}
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-cat ul li a {
  display: inline-block;
  padding: 10px 15px;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 30px;
}
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-cat ul li a.active,
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-cat ul li a:hover {
  -webkit-text-stroke: 1px var(--main-darkred);
  color: var(--main-darkred);
  background-color: #fff;
  transition: all ease 0.2s;
}
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-tool ul li a {
  display: flex;
  width: 63px;
  height: 73px;
  text-align: center;
  justify-content: center;
  align-items: center;
  transition: all ease 0.2s 0.2s;
  flex-direction: column
}
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-tool ul li a div {
  height: 35px;
}

.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-tool ul li a img {
  filter: brightness(0) invert(1);
}
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-tool ul li:nth-child(1) a div img{
  margin-top: 7px;
}
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-tool ul li:nth-child(2) a div img{
  margin-top: 7px;
}
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-tool ul li:nth-child(3) a div img{
  margin-top: 5px;
}
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-tool ul li a.active {
  background-color: #fff;
  transition: all ease 0.2s ;
  color: var(--main-red);
}

.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-tool ul li:not(:last-child) a.active img {
  filter: brightness(1) invert(0);
}

.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-tool ul li a  {
  color: #fff;
  text-transform: uppercase;
  font-size: 10px;
}
.realizzazioni-submenu .search-panel form {
  width: 100%;
}
.realizzazioni-submenu .search-panel input {
  background-color: transparent;
  border-width: 0px;
  outline-width: 0px;
  width: 100%;
  border-bottom: 1px solid #CC4045;
  font-size: 32px;
  color: #fff;
  padding: 10px 10px 10px 40px;
  background-image: url('images/search-icon.svg');
  background-repeat: no-repeat;
  background-position: center left;
}
.realizzazioni-submenu .search-panel input::placeholder {
  color: #fff;
}
.realizzazioni-submenu .search-panel .close {
  position: absolute;
  top: calc(50% - 10px);
  right: 0;
}
.realizzazioni-submenu .search-panel .close a {
  display: block;
  position: relative;
  width: 20px;
  height: 20px;
}
.realizzazioni-submenu .search-panel .close a::after {
  display: block;
  width: 25px;
  content: "";
  height: 2px;
  background-color:  #CC4045;
  top: 50%;
  position: absolute;
  transform: rotate(-45deg);
}
.realizzazioni-submenu .search-panel .close a::before {
  display: block;
  width: 25px;
  content: "";
  height: 2px;
  background-color:  #CC4045;
  top: 50%;
  position: absolute;
  transform: rotate(45deg);

}
.realizzazioni-submenu .search-panel .close a span {
  display: none;
}
.realizzazioni-submenu .search-panel .close a:hover::after,
.realizzazioni-submenu .search-panel .close a:hover::before {

  background-color:  #fff;

}
.tag ul {
  margin: 60px 0px 60px 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  flex-wrap:wrap;
  align-content: flex-start;
}
.tag ul li {
  list-style-type: none;
  margin-bottom: 27px;
  margin-left: 14px;
  margin-right: 14px;
}
.tag ul li a {
  padding: 10px 15px 10px 15px;
  border-radius: 30px;
  border: 1px solid var(--main-darkgrey);
  color: var(--main-darkgrey);
  font-weight: 600;
}
.tag ul li a span {
  font-weight: 300;
  font-size: 12px;
}
.tag ul li a:hover,
.tag ul li a.active {
  color: #fff;
  background-color: var(--main-darkgrey);
}
.post-type-archive-realizzazioni .realizzazioni-container .container,
.search .search-panel .container {
  max-width: 1370px;
}
.post-type-archive-realizzazioni .realizzazioni-container::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 0%;
  background-color: rgba(0,0,0,0.85);
  background-image: url('images/spinner-loading.svg');
  background-repeat: no-repeat;
  background-position: center 100px;
  opacity:0;
  transition: opacity linear 0.1s 0.6s, height linear 0s 0.9s;

}
.post-type-archive-realizzazioni .realizzazioni-container.preload::after {
  opacity: 1;
  height: 100%;
  transition: opacity linear 0.1s;
  overflow:hidden;
}
.tool-label {
  opacity: 0;
  transition: opacity linear 0.2s;
  height: 20px;
}
.tool-label.open,
.search-results .tool-label  {
  opacity: 1;
  height: auto;
}
.tool-label p {
  margin-top: 46px;
  margin-bottom: 46px;
  text-align: center;
  color: var(--main-darkgrey);
  font-size: 26px;
  font-weight: 600;
}
.tool-label p strong {
  font-weight: 300;
}
.tool-label p span {
  font-size: 20px;
  font-weight: 300;

}
.tool-label .close {
  position: absolute;
  top: calc(50% - 13px);
  right: 0px;
  height: 26px;

}
.tool-label .close a {
  color: var(--main-darkgrey);
  position: relative;
  display:inline-block;
  height: 26px;
  padding-right: 39px;
}
.tool-label .close a span {
  background-color: var(--main-darkgrey);
  width: 26px;
  height: 26px;
  position: relative;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: calc(50% - 13px);
  right: 0px;
}
.tool-label .close a span::before,
.tool-label .close a span::after {
  content: "";
  display: block;
  width: 16px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 8px);
  transform: rotate(-45deg);
}
.tool-label .close a span::after {
  transform: rotate(45deg);
}
.tool-label .close a:hover span::before,
.tool-label .close a:hover span::after {
  background-color: var(--main-darkred);
}
.post-type-archive-realizzazioni .realizzazioni-container .container.grid-realizzazioni,
.search-results .realizzazioni-container .container.grid-realizzazioni,
.search .realizzazioni-container .container.grid-realizzazioni {
  max-width: 1382px;
  margin-bottom: 65px;
  min-height: 300px;
}
.grid-realizzazioni {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  opacity: 1;
  transition: opacity ease 0.2s;
}
.grid-realizzazioni.hide-grid {
  opacity:0;
}
.tile-container {
  max-height: 691px;
  max-width: 50%;
  flex-basis: 50%;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  /*margin-left: 25px;
  margin-right: 25px;*/
  opacity:1;
  transition: opacity ease 0.2s 0.2s;
}
.tile-container.hide {
  opacity: 0;
}
.tile-container.sc-3,
.tile-container.sc-10,
.tile-container.sc-22 {
  flex-direction: column;
  overflow: hidden;
}
.tile-square-big {
  margin: 6px;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  overflow: hidden;

}
.tile-square-small {
  margin: 6px;
  width: calc(50% - 12px);
  height: calc(50% - 12px);
  overflow: hidden;

}
.tile-rect-hor {
  margin: 6px;
  width: calc(100% - 12px);
  height: calc(50% - 12px);
  overflow: hidden;

}
.tile-rect-ver {
  margin: 6px;
  width: calc(50% - 12px);
  height: calc(100% - 12px);
  overflow: hidden;

}
.tile-container.sc-5 .tile-square-small:first-child,
.tile-container.sc-10 .tile-square-small {
  order:1;
  overflow: hidden;

}
.tile-container.sc-5 .tile-square-small:last-child,
.tile-container.sc-10 .tile-square-small:last-child {
  order:2;
  overflow: hidden;

}
.tile-container.sc-5 .tile-rect-hor,
.tile-container.sc-10 .tile-rect-ver {
  order: 3;
  overflow: hidden;

}
.tile-container .rData {
  display:none;
}
.tile-container img {
  filter: grayscale(100%);
  object-position: 50% 50%;
  object-fit:  cover;
  width: 100%;
  height: 100%;
  transition: transform ease 0.4s;
}
.tile-container a:hover img {
  transform: scale(1.2);
}
#floatLabel {
  position: absolute;
  background-color: var(--main-red);
  height: auto;
  text-align:left;
  display:none;
  width: 330px;
  padding: 24px;
  transition: top linear 0.1s, left linear 0.1s;
  text-transform: uppercase;
}
#floatLabel div {
  color: #fff;
  font-size: 25px;
  line-height: 28px;
}
#floatLabel p {
  margin: 0px;
  color: var(--main-darkred);
}
#floatLabel.view {
  display: block
}
.r-loading {
  background-image: url('images/spinner-red-loading.svg');
  background-repeat: no-repeat;
  background-position: center center;
  height: 80px;
}
.no-result {
  text-align: center;
  color: var(--main-darkred);
  font-size: 26px;
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  opacity: 1;
  padding: 16px;
  margin: 0px;
}
.no-result.no-view {
  max-height:0px;
  opacity:0;
}
.map-infoLocation {
    font-size: 16px;
    color: var(--main-darkgrey);
    padding: 19px;
    text-transform:capitalize;
}
.map-infoTitle {
  display:block;
  padding: 0px 19px 19px 19px;
  font-size: 25px;
  line-height: 28px;
  color: var(--main-darkgrey);

}
