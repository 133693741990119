.single-realizzazioni {
  overflow-x: hidden;
}
.single-realizzazioni .realizzazioni-container {
  margin-bottom: 125px;
}
.single-realizzazioni .container.realizzazioni-container p,
.single-realizzazioni .container.realizzazioni-container .pdf-download {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}
.single-realizzazioni .container.realizzazioni-container .pdf-download {
  margin-top: 45px;
}
.single-realizzazioni .nav-single {
  display: flex;
}
.single-realizzazioni .nav-single ul {
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  height: 126px;
}
.single-realizzazioni .nav-single ul li {
  list-style-type: none;
  flex-basis: 33.3%;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: center;
}
.single-realizzazioni .nav-single ul li a {
  color: var(--main-darkgrey);
}

.single-realizzazioni .nav-single ul li:nth-child(1) {
  text-align: left;
}
.single-realizzazioni .nav-single ul li:nth-child(3) img {
  margin-left: 20px;
}
.single-realizzazioni .nav-single ul li:nth-child(1) img {
  transform: rotate(-180deg);
  margin-right: 20px;
}
.single-realizzazioni .nav-single ul li:nth-child(3) {
  text-align: right;
}
.single-realizzazioni .container.realizzazioni-container .pdf-download a {
  width: 200px;
  height: 50px;
  color: var(--main-red);
  text-align: center;
  display: flex;
  border: 1px solid var(--main-red);
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  transition: all ease 0.2s;
}
.single-realizzazioni .container.realizzazioni-container .pdf-download a:hover {
  color: #fff;
  background-color: var(--main-red)
}
.gallery-single-realizzazioni {
  position: relative;
  height: 688px;
  display:flex;
  overflow: visible;
  margin-bottom: 65px;
}
.gallery-single-realizzazioni .btn-gallery.prev{
  position: absolute;
  top: calc(305px - 64px);
  left: -143px;
  width: 128px;
  height: 128px
}
.gallery-single-realizzazioni .btn-gallery.next{
  position: absolute;
  top: calc(305px - 64px);
  right: -143px;
  width: 128px;
  height: 128px
}
.gallery-single-realizzazioni .btn-gallery a {
  display: block;
  width: 128px;
  height: 128px;
  background-color: #fff;
  border-radius: 50%;
  display: block;
  justify-content: center;
  align-items: center;
  position: relative;
}
.gallery-single-realizzazioni .btn-gallery a::after {
  content: "";
  display: block;
  border-top: 1px solid var(--main-darkgrey);
  border-left: 1px solid var(--main-darkgrey);
  width: 20px;
  height: 20px;
  top: calc(64px - 10px);
  left: calc(64px - 10px);
  position: absolute;
  transform: rotate(-45deg);
  transition: transform ease 0.5s;
}
.gallery-single-realizzazioni .btn-gallery a:hover::after {
  transform: translateX(-15px) rotate(-45deg);
}
.gallery-single-realizzazioni .btn-gallery.next a::after {
  transform: rotate(135deg);
}
.gallery-single-realizzazioni .btn-gallery.next a:hover::after {
  transform: translateX(15px) rotate(135deg);

}
.gallery-single-realizzazioni .gallery-content {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
}
.gallery-single-realizzazioni .gallery-content .gallery-items {
  position: relative;
  overflow:hidden;
  width: 100%;
  height: 610px;
}
.gallery-single-realizzazioni .gallery-content .gallery-items .gallery-single-item {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}
.gallery-single-realizzazioni .gallery-content .gallery-items .gallery-single-item:not(:first-child) {
  transform: translateX(-100%);

}
.gallery-single-realizzazioni .gallery-content .gallery-items .gallery-single-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.gallery-single-realizzazioni .gallery-content .gallery-thumb {
  position: relative;
  overflow:hidden;

  width: 100%;
  height: 68px;
}
.gallery-single-realizzazioni .gallery-content .gallery-thumb ul {
  margin:0;
  padding: 0;
  display: flex;
  height: 69px;
}
.gallery-single-realizzazioni .gallery-content .gallery-thumb ul li {
  list-style-type: none;
}
.gallery-single-realizzazioni .gallery-content .gallery-thumb ul li:not(:last-child) {
  margin-right: 18px;
}
.gallery-single-realizzazioni .gallery-content .gallery-thumb ul li a {
  display:inline-block;
  height: 68px;
  transition: all ease 0.2s;
  outline-offset: -4px;
  outline: 4px solid transparent;
}
.gallery-single-realizzazioni .gallery-content .gallery-thumb ul li a:hover,
.gallery-single-realizzazioni .gallery-content .gallery-thumb ul li a.active {
  outline-offset: -4px;
  outline: 4px solid var(--main-red);
}
.gallery-single-realizzazioni .gallery-content .gallery-thumb ul li a img {
  height: 68px;
  width: 100%;
  object-fit: cover;
}
