.page-template-page_newsletter .full-width header.container h1 {
  font-size: 57px;
  line-height: 73px;
}
.page-template-page_newsletter.page .container.default-page .wpcf7  {
    max-width: 960px;
}
.page-template-page_newsletter.page .container.default-page .wpcf7 .step-1,
.page-template-page_newsletter.page .container.default-page .wpcf7 .step-1-1.open,
.page-template-page_newsletter.page .container.default-page .wpcf7 .step-2 {
  max-width: 100%;
  margin-bottom: 23px;
  padding: 49px 40px 0px 40px;
  max-height: 6000px;
}
.page-template-page_newsletter.page .container.default-page .wpcf7 .step-2 {
  padding-bottom: 40px;
}
.page-template-page_newsletter.page .container.default-page .wpcf7 .step-1-1 {
  max-height: 0px;
  overflow:hidden;
  transition: padding-top 0.2s ease, max-height 0.2s ease, padding-bottom 0.2s ease;
}
.page-template-page_newsletter.page .container.default-page .wpcf7 .step-1-1 .tipo-intervento-item {
  width: 100%;
  max-width: none;
}
.page-template-page_newsletter.page .container.default-page .wpcf7 .step-1 h3,
.page-template-page_newsletter.page .container.default-page .wpcf7 .step-2 h3 {
  font-size: 24px;
  line-height: 26px;
  color: var(--main-darkgrey);
  text-align: center;
  margin: 0px 0px 12px 0px;
}
.page-template-page_newsletter.page .container.default-page .wpcf7 .step-1 p,
.page-template-page_newsletter.page .container.default-page .wpcf7 .step-2 p,
.page-template-page_newsletter.page .container.default-page .wpcf7 .step-1-1 .tipo-intervento-item p {
  margin: 0px 0px 30px 0px;
  text-align: center !important;
  max-width: none;
}
.page-template-page_newsletter.page .container.default-page .wpcf7 .step-1-1 .tipo-intervento-item p {
  margin-bottom: 15px;
}
.page-template-page_newsletter.page .container.default-page .wpcf7 .step-2 p {
  text-align: left;
  margin-bottom: 45px;
}
.step-1 .checkbox,
.step-2 .field-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.step-1 .checkbox .checkbox-item {
  flex-basis: 33.3%;
  flex-shrink: 0;
  flex-grow: 0;
  display: inline-flex;
  width: 33.3%;
  color: var(--main-darkgrey);
}

.step-1 .checkbox label {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--main-darkgrey);
}
.step-1 .checkbox label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.step-1 .checkbox label .wpcf7-list-item-label {
  position: absolute;
  top: 3px;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #eee;
  border-radius: 50%;
  border: 2px solid #ECECEC;
  box-shadow: 0 0 0 1px var(--main-darkgrey);
  font-size: 0px;
}
.step-1 .checkbox label:hover input ~ .wpcf7-list-item-label {
  background-color: var(--main-grey);
}
.step-1 .checkbox label input:checked ~ .wpcf7-list-item-label {
  background-color: var(--main-darkgrey);
}
.page-template-page_newsletter.page .container.default-page div.step-2 .field-container,
.page-template-page_newsletter.page .container.default-page div.step-2 .field-container div,
.page-template-page_newsletter.page .container.default-page div.step-2 .privacy
{
  width: 100%;
  max-width: none;
}

.page-template-page_newsletter.page .container.default-page div.step-2 .field-container .field {
  flex-basis: calc(50% - 30px);
  flex-shrink: 0;
  flex-grow: 0;
  width: calc(50% - 30px);
  display: flex;
  flex-direction: column;
  margin-bottom: 62px;
  margin-left: 30px;
  margin-right: 0px;
}
.page-template-page_newsletter.page .container.default-page div.step-2 .field-container .field:nth-child(2n+1) {
  margin-left: 0px;
  margin-right: 30px;
}
.page-template-page_newsletter.page .container.default-page div.step-2 .field-container .field.full {
  flex-basis: 100%;
  width: 100%;
}
.page-template-page_newsletter.page .container.default-page div.step-2 .field-container .field label {
  font-size: 15px;
  line-height: 14px;
}
.page-template-page_newsletter.page .container.default-page div.step-2 .field-container .field input,
.page-template-page_newsletter.page .container.default-page div.step-2 .field-container .field textarea,
.page-template-page_newsletter.page .container.default-page div.step-2 .field-container .field input:focus {
  border-width: 0px;
  border-bottom: 2px solid #d8d8d8;
  background-color: transparent;
  outline-width: 0px;
  font-size: 15px;
  padding: 8px 0px 3px 0px;
  width: 100%;
  border-radius: 0px;
  font-family: var(--main-font);
}
.page-template-page_newsletter.page .container.default-page div.step-2 .field-container .field textarea {
  border: 2px solid #d8d8d8;
  padding: 5px;
}
.page-template-page_newsletter.page .container.default-page div.step-2 .privacy p {
  margin: 0px;
}
.page-template-page_newsletter.page .container.default-page div.step-2 .privacy label {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 30px !important;
}
.page-template-page_newsletter.page .container.default-page div.step-2 .privacy label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.page-template-page_newsletter.page .container.default-page div.step-2 .privacy label .checkbox-custom {
  position: absolute;
  top: 3px;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #eee;
  border: 1px solid var(--main-grey);
  margin-bottom: 0px;
}
.page-template-page_newsletter.page .container.default-page div.step-2 .privacy label:hover input ~ .wpcf7-list-item-label .checkbox-custom {
  background-color: var(--main-grey);
}
.page-template-page_newsletter.page .container.default-page div.step-2 .privacy label input:checked ~ .wpcf7-list-item-label .checkbox-custom {
  background-color: var(--main-darkgrey);
}
.page-template-page_newsletter.page .container.default-page div.step-2 .privacy label input ~ .wpcf7-list-item-label .checkbox-custom::after {
  content: "";
  position: absolute;
  display: none;
}
.page-template-page_newsletter.page .container.default-page div.step-2 .privacy label input:checked ~ .wpcf7-list-item-label .checkbox-custom::after{
  display: block;
}
.page-template-page_newsletter.page .container.default-page div.step-2 .privacy label input:checked ~ .wpcf7-list-item-label .checkbox-custom::after {
  left: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.page-template-page_newsletter.page .container.default-page div.step-2 .btn_submit {
  text-align: center;
}
.page-template-page_newsletter.page .container.default-page div.step-2 .btn_submit input {
  width: 251px;
  height: 63px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--main-red);
  border-width: 0px;
  color: #fff;
  cursor: pointer;
  outline-width: 0px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1.52px;
  -webkit-appearance: none;
  border-radius: 0px;
}
.page-template-page_newsletter.page .container.default-page div.step-2 .btn_submit input:hover {
  background-color: var(--main-darkgrey);
}
.screen-reader-response,
.step-1 .checkbox .wpcf7-not-valid-tip {
  display: none;
}
.step-2 .field-container .field .wpcf7-not-valid-tip,
.step-2 .privacy .wpcf7-not-valid-tip,
.step-1 .checkbox-tipo-intervento .wpcf7-not-valid-tip{
  color: var(--main-red);
  font-size: 13px;
  display:block;
}
.page-template-page_newsletter.page .container.default-page .response {
  max-width: none;
  width: 100%;
  margin-bottom: 23px;
}
.page-template-page_newsletter.page .container.default-page .response .wpcf7-display-none {
  display: none;
}
.page-template-page_newsletter.page .container.default-page .response .wpcf7-response-output{
  max-width: none;
  width: 100%;
  background-color: var(--main-red);
  color: #fff;
  text-align: center;
  padding: 0px;
}
.page-template-page_newsletter.page .container.default-page .response .wpcf7-mail-sent-ok{
  background-color: #32CD32;

}
.page-template-page_newsletter.page .container.default-page .step-1 .checkbox-tipo-intervento {
  margin-top: 28px;
}
.page-template-page_newsletter.page .container.default-page .step-1 .checkbox-tipo-intervento .wpcf7-exclusive-checkbox,
.page-template-page_newsletter.page .container.default-page .step-1-1 .tipo-edificio  .wpcf7-exclusive-checkbox,
.page-template-page_newsletter.page .container.default-page .step-1-1 .anni-edificio .wpcf7-exclusive-checkbox,
.page-template-page_newsletter.page .container.default-page .step-1-1 .sopralluogo .wpcf7-exclusive-checkbox,
.page-template-page_newsletter.page .container.default-page .step-1-1 .tempistiche .wpcf7-exclusive-checkbox,
.page-template-page_newsletter.page .container.default-page .step-1-1 .isolare .wpcf7-exclusive-checkbox {
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content: space-evenly;
}
.page-template-page_newsletter.page .container.default-page .step-1 .checkbox-tipo-intervento .wpcf7-list-item,
.page-template-page_newsletter.page .container.default-page .step-1-1 .tipo-edificio .wpcf7-list-item,
.page-template-page_newsletter.page .container.default-page .step-1-1 .anni-edificio .wpcf7-list-item,
.page-template-page_newsletter.page .container.default-page .step-1-1 .sopralluogo .wpcf7-list-item,
.page-template-page_newsletter.page .container.default-page .step-1-1 .tempistiche .wpcf7-list-item,
.page-template-page_newsletter.page .container.default-page .step-1-1 .isolare .wpcf7-list-item {
  flex-basis: 25%  ;
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  margin-left: 13px;
  margin-right: 13px;
  margin-bottom: 20px;
  text-align: center;
  justify-content: center;
}
.page-template-page_newsletter.page .container.default-page .step-1-1 .anni-edificio .wpcf7-list-item {
    flex-basis: 20%  ;
}
.page-template-page_newsletter.page .container.default-page .step-1-1 .sopralluogo .wpcf7-list-item.first {
  justify-content:flex-end;
}
.page-template-page_newsletter.page .container.default-page .step-1-1 .sopralluogo .wpcf7-list-item.last {
  justify-content:flex-start;
}
.page-template-page_newsletter.page .container.default-page .step-1 .checkbox-tipo-intervento .wpcf7-list-item label,
.page-template-page_newsletter.page .container.default-page .step-1-1 .tipo-edificio .wpcf7-list-item label,
.page-template-page_newsletter.page .container.default-page .step-1-1 .sopralluogo .wpcf7-list-item label,
.page-template-page_newsletter.page .container.default-page .step-1-1 .tempistiche .wpcf7-list-item label,
.page-template-page_newsletter.page .container.default-page .step-1-1 .anni-edificio .wpcf7-list-item label,
.page-template-page_newsletter.page .container.default-page .step-1-1 .isolare .wpcf7-list-item  label {
  cursor: pointer;
}
.page-template-page_newsletter.page .container.default-page .step-1 .checkbox-tipo-intervento .wpcf7-list-item label input,
.page-template-page_newsletter.page .container.default-page .step-1-1 .tipo-edificio .wpcf7-list-item label input,
.page-template-page_newsletter.page .container.default-page .step-1-1 .sopralluogo .wpcf7-list-item label input,
.page-template-page_newsletter.page .container.default-page .step-1-1 .tempistiche .wpcf7-list-item label input,
.page-template-page_newsletter.page .container.default-page .step-1-1 .anni-edificio .wpcf7-list-item label input,
.page-template-page_newsletter.page .container.default-page .step-1-1 .isolare .wpcf7-list-item label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.page-template-page_newsletter.page .container.default-page .step-1 .checkbox-tipo-intervento .wpcf7-list-item label span,
.page-template-page_newsletter.page .container.default-page .step-1-1 .tipo-edificio .wpcf7-list-item label span,
.page-template-page_newsletter.page .container.default-page .step-1-1 .sopralluogo .wpcf7-list-item label span,
.page-template-page_newsletter.page .container.default-page .step-1-1 .tempistiche .wpcf7-list-item label span,
.page-template-page_newsletter.page .container.default-page .step-1-1 .anni-edificio .wpcf7-list-item label span,
.page-template-page_newsletter.page .container.default-page .step-1-1 .isolare .wpcf7-list-item label span {
  display: flex;
  padding: 5px 10px 5px 10px;
  border: 1px solid var(--main-darkgrey);
  height: 40px;
  border-radius: 20px;
  color: var(--main-darkgrey);
  font-size: 16px;
  line-height: 15px;
  vertical-align: middle;
  align-items:center;
  min-width: 50px;
  text-align: center;
  justify-content: center;
}
.page-template-page_newsletter.page .container.default-page .step-1 .checkbox-tipo-intervento .wpcf7-list-item label:hover span,
.page-template-page_newsletter.page .container.default-page .step-1 .checkbox-tipo-intervento .wpcf7-list-item label input:checked ~ span,
.page-template-page_newsletter.page .container.default-page .step-1-1 .tipo-edificio .wpcf7-list-item label:hover span,
.page-template-page_newsletter.page .container.default-page .step-1-1 .tipo-edificio .wpcf7-list-item label input:checked ~ span,
.page-template-page_newsletter.page .container.default-page .step-1-1 .sopralluogo .wpcf7-list-item label:hover span,
.page-template-page_newsletter.page .container.default-page .step-1-1 .sopralluogo .wpcf7-list-item label input:checked  ~ span,
.page-template-page_newsletter.page .container.default-page .step-1-1 .tempistiche .wpcf7-list-item label:hover span,
.page-template-page_newsletter.page .container.default-page .step-1-1 .tempistiche .wpcf7-list-item label input:checked  ~ span,
.page-template-page_newsletter.page .container.default-page .step-1-1 .anni-edificio .wpcf7-list-item label:hover span,
.page-template-page_newsletter.page .container.default-page .step-1-1 .anni-edificio .wpcf7-list-item label input:checked  ~ span,
.page-template-page_newsletter.page .container.default-page .step-1-1 .isolare .wpcf7-list-item label:hover span,
.page-template-page_newsletter.page .container.default-page .step-1-1 .isolare .wpcf7-list-item label input:checked ~ span{
  background-color:var(--main-darkgrey);
    color: #fff;
}

#superficie .range {
  width: 84%;
    display: inline-block;
}
#superficie .range input {
  width: 100%;
  -webkit-appearance: none;
  background-color: var(--main-grey);
  opacity: 0.5;
}
#superficie .range input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 14px;
  background: var(--main-darkgrey);
  cursor: pointer;
}

#superficie .range input::-moz-range-thumb {
  width: 25px;
  height: 14px;
  background: var(--main-darkgrey);
  cursor: pointer;
}
#superficie .output-value {
  width: 15%;
  text-align: right;
  display: inline-block;
  vertical-align: text-bottom;
  color: var(--main-grey);
  font-weight: bold;
  line-height: 20px;
}
.page-template-page_newsletter.page .container.default-page .step-1-1 .tipo-intervento-item{
  opacity: 0;
  max-height: 0px;
  overflow:hidden;
  transition: opacity 0.2s ease, max-height 0.2s ease, margin-bottom 0s 0.1s ease;
}
.page-template-page_newsletter.page .container.default-page .step-1-1 .tipo-intervento-item.visible:not(:last-child) {
  margin-bottom: 30px;
}
.page-template-page_newsletter.page .container.default-page .step-1-1 .tipo-intervento-item.visible {
  opacity: 1;
  max-height: 6000px;
  transition: opacity 0.2s 0.2s ease;
}
.radio-newsletter {
  padding-bottom: 10px;
}
.page-template-page_newsletter .radio-newsletter .wpcf7-form-control {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.page-template-page_newsletter .radio-newsletter  .wpcf7-list-item {
  flex-basis: 33%;
  flex-grow: 0;
  flex-shrink: 1;
}
