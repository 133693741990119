@media only screen and (min-width: 769px) and (max-width: 1024px) {
  body nav.main-menu .realizzazioni-link ul li a img  {
    object-fit: cover;
    width: 30%;
    height: 100%;
    filter: grayscale(100%);
    max-width: 30%;
    object-position: center right;
  }
  body nav.main-menu .realizzazioni-link ul li a span {

    left: calc(30%);
    max-width: 70%;


  }
.post-type-archive-realizzazioni .realizzazioni-submenu .container {
  margin-left: 20px;
  margin-right: 20px;
}
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-cat ul,
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-tool ul {
    align-items: stretch;
}

.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-cat ul li a {
  display:flex;
  align-items: center
}
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-tool {
  margin-left: 10px;
}
.post-type-archive-realizzazioni .realizzazioni-submenu .container .realizzazioni-tool ul li {
  align-items: center
}
  .home-red-square {
    padding: 40px;
    display:flex;
    justify-content: center;
    align-items: center;
  }
  .home-red-square .textwidget {
    display:none;
  }
  .under-news .icon-container .icon .icon-img {
    max-width: 184px;
    max-height: 184px;
    width: 100%;
    height: 100%;
  }
  .under-slider .under-slider-content .under-slider-image {

    right: 10px;
  }
  .settori-intervento-container .settori-grid .settore {
    height: 400px;
  }
  .story-article.right .anno,
  .story-article.left .anno,
  .story-article.special .anno {
    right: auto;
    left: auto;
    z-index: 1;
  }
}
