.category-la-nostra-storia .full-width.header {
  position: relative;
  /*z-index: -1*/
}
.category-la-nostra-storia .full-width.header h1 {
  color: #fff;
}
.category-la-nostra-storia article.container {
  max-width: 1440px;
  position: relative;
  padding-top: 115px;
  overflow-x: hidden;
}
.category-la-nostra-storia article.container::before {
  content: "";
  display: block;
  height: 100%;
  width: 1px;
  background-color: var(--main-red);
  margin: auto;
  position: absolute;
  top: 0;
  left: 50%;
}
.story-start {
  /*height: 112px;*/
  height: 40px;
  position:absolute;
  bottom: 0px;
}
.story-start::after {
  content: "";
  display: block;
  height: 100%;
  width: 1px;
  background-color: #fff;
  margin: auto;
}
.story-start > div {
  width: 28px;
  height: 28px;
  border: 1px solid #fff;
  border-radius: 50%;
}
.story-start > div a {
  display:block
}
.story-start > div .arrow-start {
  transform: rotate(-45deg);
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  width: 9px;
  height: 9px;
  margin: 7px auto auto auto;
}
.story-article {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  margin-bottom: 250px;
  max-width: 1022px;
  margin-left: auto;
  margin-right: auto;

}
.story-article.full {
  max-width: 960px;
  background-color: #fff;
  padding: 50px;
}
.story-article.full a.leggi {
  position: absolute;
  bottom: 10px;
  left: 0px;
  width: 100%;
  text-align: center;
}
.story-article.special .col-dx {
  flex-basis: 50%;
  flex-grow: 1;
  flex-shrink: 1;
  order: 2;
  display: flex;
  flex-direction: column;
}
.story-article.special .col-sx {
  flex-basis: 50%;
  flex-grow: 1;
  flex-shrink: 1;
  order: 1;
  display: flex;
}
.story-article.special .col-dx .story-text  {
  order:2
}
.story-article.special .col-dx .story-image  {
  order:1
}
.story-article .story-text,
.story-article .story-image,
.story-article .story-gallery {
  flex-basis: 50%;
  flex-grow: 1;
  flex-shrink: 1;
}

.story-article.right .story-text {
  padding-left: 75px;
  order: 2;
}
.story-article.left .story-text {
  padding-right: 75px;
  text-align: right
}
.story-article.special .story-text {
  position: relative;
}
.story-article.right .story-image,
.story-article.right .story-gallery {
  order: 1;
}
.story-article.right .story-text::before {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--main-red);
  position: absolute;
  top: 50px;
  left: calc(50% - 3px);
  border-radius: 50%;
}
.story-article.left .story-text::before {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--main-red);
  position: absolute;
  top: 50px;
  right: calc(50% - 3px);
  border-radius: 50%;
}
.story-article.special .story-text::before {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--main-red);
  position: absolute;
  top: 0px;
  left: -3px;
  border-radius: 50%;
}
.story-article .story-text h2 {
  color: var(--main-red);
  font-size: 26px;
  margin-top: 32px;
  margin-bottom: 32px;
}
.story-article.left .story-text h2 {
text-align: right;
}
.story-article.full .story-text h2 {
  text-align: center;
  margin-top: 85px;
}
.story-article.special .story-text h2 {
  margin-top: 0px;
}
.story-article.full .story-text .text-col {
  column-count: 2;
  column-gap: 36px;
}
.story-article.full .story-text .text-col p {
  margin-top: 0px;
  margin-bottom: 32px;
}
.story-article .story-image {
  overflow: hidden;
}
.story-article .story-image img {
  object-fit: contain;
  width: 100%;
  height: auto;
  filter: grayscale(100%);
  object-position: 50% 50%;
}
.story-article.right .anno {
  position: absolute;
  top: -70px;
  right: -200px;
  overflow: auto;
  text-align: right;
  padding: 50px;
  background-image: url('images/diagonal-lines.svg');
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.story-article.left .anno {
  position: absolute;
  top: -70px;
  left: -200px;
  overflow: auto;
  text-align: right;
  padding: 50px;
  background-image: url('images/diagonal-lines.svg');
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.story-article.full .anno {
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  overflow: auto;
  text-align: center;
  padding: 0px;
  background-image: url('images/diagonal-lines.svg');
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.story-article.special .anno {
  position: absolute;
  bottom: -100px;
  right: -200px;
  overflow: auto;
  text-align: right;
  padding: 50px;
  background-image: url('images/diagonal-lines.svg');
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  z-index: 1;
}
.story-article .anno p {
  font-size: 122px;
  font-weight: bold;
  margin: 0px;
  padding: 50px 0px;
  color: var(--main-red);
}
.story-article .story-gallery {
  display: flex;
  flex-direction: column;

}
.story-article .story-gallery .story-gallery-imgContainer {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 100%;
}
.story-article .story-gallery .story-gallery-imgContainer .image-gallery {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
  z-index: -1;
}
.story-article .story-gallery .story-gallery-imgContainer .image-gallery img {
  object-fit: contain;
  width: 100%;
  height: auto;
  object-position: 50% 50%;
  filter: grayscale(100%);
}
.story-article.special .story-image {
  background-color: var(--main-red);
  overflow: visible;
  z-index: -1;
  position:relative;
}
.story-article.special .story-image img {
  margin-top: -85px;
  object-fit: cover;
}
.story-article.special .story-text {
  background-color: #fff;
  margin-left: 1px;
  margin-right: 70px;
  margin-top: -160px;
  padding: 50px;
}
.story-article.special .story-gallery {
  margin-top: 30px;
}
.story-article.special .story-gallery .story-gallery-imgContainer .image-gallery img {
  width: auto;
  height: 100%;
}

.story-article.special .story-gallery .story-gallery-imgContainer .image-gallery{
  height: 100%;
  overflow: hidden;
}
.pop-up {
  max-width: 930px;
  width: 0;
  height: 0;
  position: fixed;
  top: 0;
  left: auto;
  right: auto;
  z-index: 1000;
  visibility: hidden;
  transform: translateY(-20%);
  opacity: 0;
  transition: transform ease 0.2s,
              opacity ease 0.2s,
              visibility ease 0s 0.2s,
              width ease 0s 0.2s,
              height ease 0s 0.2s,
              top ease 0s 0.2s;
  display: flex;
  flex-direction: column;
;
}
.pop-up.active {
  width: calc(100% - 50px);
  height: calc(100vh - 100px);
  top: 50px;
  visibility: visible;
  transform: translateY(0);
  opacity: 1;
  transition: transform ease 0.2s,
              opacity ease 0.2s;
}
.pop-up.active .close {
  margin-bottom: 20px;
}
.pop-up.active .close a{
  float: right;
  display: block;
  width: 23px;
  height: 23px;
  position: relative;
}
.pop-up.active .close a::after {
  content: "";
  display: block;
  height: 2px;
  width: 23px;
  background-color: #fff;
  transform: rotate(-45deg);
  position: absolute;
  top: 0;
  left: 0;
  transition: all ease 0.2s;
  margin-top:13px;
}
.pop-up.active .close a::before {
  content: "";
  display: block;
  height: 2px;
  width: 23px;
  background-color: #fff;
  transform: rotate(45deg);
  top: 1px;
  left: 0;
  transition: all ease 0.2s;
  margin-top:13px;


}
.pop-up.active .close a:hover::after,
.pop-up.active .close a:hover::before {

  background-color: var(--main-red);

}
.pop-up.active .pop-up-content {
  flex-grow: 1;
  flex-shrink: 1;
  background-color: #fff;
  overflow:hidden;
  padding: 0px;
  padding: 80px 40px 80px 80px;
  position:relative;
}
.pop-up.active .pop-up-content .scroll {
  overflow-x: hidden;
  overflow-y: auto;
  width: calc(100% - 40px - 80px);
  height: calc(100% - 80px - 80px);
  padding-right: 40px;
  position: absolute;
}
.pop-up.active .pop-up-content .scroll::-webkit-scrollbar {
  width: 6px;
}

.pop-up.active .pop-up-content .scroll::-webkit-scrollbar-track {
  background: rgba(0,0,0,0);
}
.pop-up.active .pop-up-content .scroll::-webkit-scrollbar-thumb {
  background: var(--main-darkgrey);
  border-radius: 3px 3px 3px 3px;
}
.pop-up.active .pop-up-content .anno {

  background-image: url('images/diagonal-lines.svg');
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: left;
  padding-left: 100px;
}
.pop-up.active .pop-up-content .anno p {
  font-size: 122px;
  font-weight: bold;
  margin: 0px;
  padding: 50px 0px;
  color: var(--main-red);
}
.pop-up.active .pop-up-content header {

  margin-top: -80px;

}
.pop-up.active .pop-up-content header h1 {
    color: var(--main-red);
    font-size: 43px;
    line-height: 47px;
}
