.pulsanti {
  position: relative;
  margin-top: 10px;
  margin-left: 0px;
  margin-right: 50%;
}
.home-slider-navbar .pulsanti {
  margin-left: 25%;
  margin-right: 25%;
}
.left .pulsanti {
  margin-left: 50%;
  margin-right: 0px;
}
.pulsanti ul {
  display:flex;
  margin: 0px;
  padding: 0px;
  flex-direction: row;
  width: 100%;
}
.pulsanti ul li {
  flex-basis: 50%;
  flex-shrink: 1;
  flex-grow:1;
  list-style-type: none;
}
.pulsanti ul li a,
.home  .home-slider-navbar .pulsanti ul li a {
  display: flex;
  width: 100%;
  height: 26px;
  transition: background-color ease 0.5s;
  position:relative;
  justify-content: flex-end;
  align-items: center;
  font-size: 10px;
  text-decoration: none;
  color: var(--main-red);
}

.page-template-page_qualita .qualita-gallery .pulsanti ul li a,
.home .pulsanti ul li a {
  color: var(--main-grey);
}
.pulsanti ul li:nth-child(2) a {
  justify-content: flex-start;
}
.pulsanti  ul li a span {
  flex-basis: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  height: 26px;
  line-height: 27px;

}
.pulsanti  ul li:nth-child(2) a span,
.home  .home-slider-navbar .pulsanti  ul li:nth-child(2) a span {
  text-align: right;
  border-left: solid 1px red;
}
.page-template-page_qualita .qualita-gallery .pulsanti  ul li:nth-child(2) a span,
.home .pulsanti  ul li:nth-child(2) a span {
  border-left: solid 1px var(--main-grey);

}
.pulsanti .pointer,
.home  .home-slider-navbar .pulsanti .pointer {
  position: absolute;
  width: calc(50% - 5px);
  height: 1px;
  background-color: var(--main-red);
  transform-origin: 100% 0%;
  top: 50%;
  left: 5px;
}
.page-template-page_qualita .qualita-gallery .pulsanti .pointer,
.home  .pulsanti .pointer {
    background-color: var(--main-grey);
}
.pulsanti-new {
    display: flex;
}
.pulsanti-new.center {
  display: flex;
  justify-content: center;
}
.pulsanti-new ul{
  width: 125px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}
.pulsanti-new  ul li {
  list-style-type: none;
  width: 50px;
}
.pulsanti-new  ul li a img {
  width: 100%;
  filter: brightness(100);
  transition: all ease 0.2s;
}
.pulsanti-new.mainred  ul li a img {
  width: 100%;
  filter: brightness(1);
  transition: all ease 0.2s;
}
.pulsanti-new  ul li:first-child img {
  transform: rotate(-180deg);
}
.pulsanti-new  ul li a:hover img {
  filter: brightness(1);
}
.pulsanti-new.mainred  ul li a:hover img {
  filter: brightness(0);
}

.pulsanti-new.black  ul li a img {
  filter: brightness(0);
}
.pulsanti-new.black ul li a:hover img {
  filter: brightness(1);
}
