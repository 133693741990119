
.full-width.preventivo {
  position: relative;
  background-image: url('images/preventivi-background.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;
  overflow: hidden;
}
.full-width.preventivo::before {
  content: "";
  display: block;
  max-width: 1440px;
  width: 68%;
  height: 100%;
  position: absolute;
  transform: skewX(45deg);
  background-color: rgba(145,22,26,0.75);
  transform-origin: 50% 50%;
}

.full-width.preventivo .container {
  position: relative;
}
.full-width.preventivo .container h6 {
  font-size: 54px;
  line-height: 47px;
  color: #fff;
  margin-top: 97px;
  margin-bottom: 13px;
}
.full-width.preventivo .container p {
  margin: 0px 0px 57px 0px;
  font-size: 24px;
  line-height: 26px;
  color: #fff;
  letter-spacing: 3px;
}
