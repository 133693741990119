.list-news {
  position: relative;
  width: 100%;
  background-color: #fff;
  margin-bottom: 48px;
  box-shadow: 0px 0px 32px rgba(0,0,0,0.1);
  padding: 60px;
}
.list-news p img,
.list-news img {
  width: 100%;
  height: auto;
}
.container.news {
  max-width: 720px;
}
.news .anno {
  display: none;

}
.news footer time {
  font-size: 13px;
  color: var(--main-darkgrey);
  text-transform: uppercase;
  letter-spacing: 1.52px;
  margin-bottom: 10px;
}
.news header h1 {
  text-align: left;
  font-size: 57px;
  line-height: 73px;
  margin-top: 0px;
  margin-bottom: 20px;
}
.news img {
  width: 100%;
  height: auto;
}
.news .news-thumb {
  margin-bottom: 95px;
}
/*
.news .content p:first-child::first-letter {
  background-color: var(--main-darkgrey);
  color: #fff;
  display:block;
  float: left;
  border-radius: 50%;
  font-size:39px;
  width: 78px;
  height: 78px;
  padding: 25px;
  margin-right: 14px;
  text-align: center;
  vertical-align:  middle;
}*/
.news .content p span.capolettera {
  background-color: var(--main-darkgrey);
  color: #fff;
  display:block;
  float: left;
  border-radius: 50%;
  font-size:39px;
  width: 78px;
  height: 78px;
  padding: 25px;
  margin-right: 14px;
  text-align: center;
  vertical-align:  middle;
}
.news p {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 45px;
}
.news .nav-news {
  position: absolute;
  top: 640px;
  right: 0px;
}
.news .nav-news ul {
  background-color: #fff;
  box-shadow: 0px 0px 32px rgba(0,0,0,0.1);
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  border: 1px solid #ededed;
  border-right-width: 0px;
}
.news .nav-news ul li {
  list-style-type: none;
}
.news .nav-news ul li a {
  display:block;
  width: 70px;
  height: 70px;
  padding: 20px;
}
.news .nav-news ul li:first-child a {
  padding: 24px;
}
.news .nav-news ul li:last-child a img {
  transform: rotate(180deg);
}
.news .nav-news ul li:not(:last-child) a {
    border-bottom: 1px solid #ededed;
}
.news .nav-news ul li a span {
  display: none;
}
