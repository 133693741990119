.page-template-page_qualita .content {
  width: 100%;
}
.page .full-width.qualita header.container {
  background-image: url('images/qualita.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 100px) 30%;
}
.page .full-width.qualita header.container h1 {
  font-size: 57px;
  line-height: 73px;
  margin-top: 0px;
  margin-bottom: 20px;
  color: var(--main-darkgrey);
  margin-left: 100px;
  margin-right: 100px;
}
.page .full-width.qualita header.container h1 strong {
  font-size: 104px;
  line-height: 73px;
}
.qualita .qualita-thumb {
  width: 100%;
  line-height: 0;
}
.qualita .qualita-thumb img {
  object-fit: cover;
  width: 100%;
  height: auto;
  filter: grayscale(100%);
}
.page-template-page_qualita .insertion {
  overflow: visible;

}
.page-template-page_qualita .insertion .container {
  display:flex;
  flex-direction: row;
}
.page-template-page_qualita .qualita-servizio .container > div {
  flex-basis: 50%;
  flex-shrink: 1;
  flex-grow: 1;
  position: relative;

}
.page-template-page_qualita .qualita-servizio .container .col-sx {
  order: 1;
}
.page-template-page_qualita .qualita-servizio .container .col-dx {
  order: 2;
}
.page-template-page_qualita .qualita-servizio .container .col-sx::after {
  display: block;
  content: "";
  width: 260px;
  height: 260px;
  background-image: url('images/diagonal-lines.svg');
  position: absolute;
  bottom: -138px;
  left: -200px;
}

.page-template-page_qualita .insertion .container {
  /*margin-bottom: 250px;*/
}
.page-template-page_qualita .insertion .container .col-dx h2 {
  text-align: left;
  font-size: 13px;
  margin-bottom: 18px;
}
.page-template-page_qualita .insertion .container .col-dx h3 {
  font-size: 43px;
  line-height: 47px;
  text-align: left;
  margin-bottom: 40px;
}
.page-template-page_qualita .insertion .container .col-dx p {
  text-align: left;
}
.page-template-page_qualita .insertion .container .col-dx figure {
  position: absolute;
  margin: 0px;
  bottom: -242px;

}
.page-template-page_qualita .insertion.qualita-servizio .container .col-sx img {
  margin-top: 161px;
  margin-right: 75px;
}
.page-template-page_qualita .container.default-page div.wheel-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: none;
  margin-top: 190px;
  margin-bottom: 100px;
}
.page-template-page_qualita .percentuali {
  display:flex;
}
.page-template-page_qualita .percentuali .container {
  display: flex;
  flex-direction: row;
  color: var(--main-darkgrey);
}
.page-template-page_qualita .percentuali .container .item-percentage {
  flex-shrink: 1;
  flex-grow: 1;
}
.page-template-page_qualita .percentuali .container .item-percentage p {
  color: var(--main-darkgrey);
  font-size: 35px;
  line-height: 39px;
  font-weight: bold;
  text-align: center;
}
.page-template-page_qualita .percentuali .container .item-percentage p strong {
  font-size: 118px;
  line-height: 130px;
  -webkit-text-stroke: 1px var(--main-darkgrey);
  text-stroke: 1px var(--main-darkgrey);
  color: #eeeff3;
}
.page-template-page_qualita .qualita-gallery  .container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.page-template-page_qualita .qualita-gallery .container .col-sx {
  flex-basis: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  padding-right: 37px;
  margin-top: 70px;
  margin-bottom: 70px;
}
.page-template-page_qualita .qualita-gallery .container .col-sx h2 {
  color: #fff;
  font-size: 43px;
  line-height: 47px;
  text-align: left;
  font-weight: bold;
  margin: 0px 0px 36px 0px
}
.page-template-page_qualita .qualita-gallery .container .col-dx-gallery {
  flex-basis: 50%;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
  padding-left: 37px;
  margin-top: 70px;
}
.page-template-page_qualita .qualita-gallery .container .col-dx-gallery ul {
  flex-basis: 50%;
  flex-shrink: 1;
  flex-grow: 1;
  margin:0;
  padding: 0;
  display: flex;
  width:268px;
  transition: transform ease 0.2s;
}
.page-template-page_qualita .qualita-gallery .container .col-dx-gallery ul li {
  list-style-type: none;
  margin-right: 30px;
  opacity: 0.5;
  transition: opacity ease 0.2s;
}
.page-template-page_qualita .qualita-gallery .container .col-dx-gallery ul li img {
  object-fit: contain;
  height: auto;
  width: 238px;
}
.page-template-page_qualita .qualita-gallery .container .col-dx-gallery ul li.active {
  opacity: 1;
}
