.full-width.esperienza {
  position: relative;
  background-image: url('images/preventivi-background.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;
  overflow: hidden;
}
.full-width.esperienza::before {
  content: "";
  display: block;
  max-width: 1440px;
  width: 68%;
  height: 100%;
  position: absolute;
  transform: skewX(45deg);
  background-color: rgb(0,0,0);
  transform-origin: 50% 50%;
}
.full-width.esperienza .story-endline {
  height: 115px;
  position: relative;
  margin-bottom: 35px;
  width: 1px;
  background-color: #fff;
}
.full-width.esperienza .story-endline::after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: calc(50% - 3px);
}
.full-width.esperienza .container {
  position: relative;
}
.full-width.esperienza .container h6 {

  color: #fff;
  margin-top: 0px;
  margin-bottom: 36px;
  font-size: 54px;
  line-height: 47px;
}
.full-width.esperienza .container p {
  margin: 0px 0px 123px 0px;
  font-size: 24px;
  line-height: 26px;
  color: #fff;
  letter-spacing: 3px;
}
