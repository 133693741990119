.cta-contatti {
  text-align: center;
  background-image: url('images/chiesa-predazzo.jpg');
  background-size:cover;
  background-position: center center;
}
.cta-contatti h6 {
    padding-top: 176px;
    margin: 0px 0px 33px 0px;
    font-size: 32px;
    line-height: 45px;
    font-weight:  300;
}
.cta-contatti p {
  margin-bottom: 127px;
}
