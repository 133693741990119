ul.social-share {
  position: absolute;
  right: 30px;
  bottom: 10px;
/*  width: 36px;
  height: 36px;*/
  width: 70px;
  height: 50px;
  background-color: #fff;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  text-align: center;
  transition: width ease 0.2s;
}

ul.social-share:hover {
  width: 214px;
}
ul.social-share li {
  list-style-type: none;
  float:left;
    background-color: #fff;
    height: 50px;
}
ul.social-share li:last-child {
  position: absolute;
  top: 0px;
  right: 0px;
/*  width: 36px;
  height: 36px;*/
  width: 70px;
  height: 50px;
  cursor: pointer;
  line-height: 17px;
}

ul.social-share li a {
  display: block;
  width: 36px;
  height: 36px;
  float: left;
}
ul.social-share li:not(:last-child) a:hover {
  filter: opacity(0.5);
}
ul.social-share li:last-child img {
max-width: 20px;
height: auto;
margin-top: 6px;
}
ul.social-share li a[href*="wa.me"] img[alt="whatsapp"]{
  max-width: 24px;
  height: auto;
  margin-top: calc(50% - 13px);
}

ul.social-share li a[href*="facebook.com"] img[alt="facebook"]{
  max-height: 22px;
  width: auto;
  margin-top: calc(50% - 12px);
}
ul.social-share li a[href*="linkedin.com"] img[alt="linkedin"]{
  max-height: 23px;
  width: auto;
  margin-top: calc(50% - 12px);
}
ul.social-share li a[href*="twitter.com"] img[alt="twitter"]{
  max-height: 19px;
  width: auto;
  margin-top: calc(50% - 10px);
}
ul.colored.social-share li a[href*="wa.me"] {
  background-color: #25D366;
}
ul.colored.social-share li a[href*="facebook.com"] {
  background-color: #3b5998;
}
ul.colored.social-share li a[href*="twitter.com"] {
  background-color: #00acee;
}
ul.colored.social-share li a[href*="linkedin.com"] {
  background-color: #0077B5;
}
ul.social-share li a span{
  display:none;
}
ul.social-share > li  span {
  font-size: 10px;
  text-transform: uppercase;
  color: var(--main-darkgrey);
}


/* SOCIAL SHARING INTERNO ALLA SINGOLA NEWS */
.news ul.social-share {
  position: static;
  margin: 0px auto 115px auto;
  padding: 0px;
  overflow: hidden;
  text-align: center;
  max-width: 480px;
  width:auto;
  background-color: rgba(0,0,0,0);
}
.news ul.social-share li:last-child {
  position: static;
  display:none;
}
.news ul.social-share li {
    background-color: rgba(0,0,0,0);
}

/* social sharing page */
.page-news ul li ul.social-share,
.single-realizzazioni  ul.social-share  {
  right: 0px;
  bottom: 0px;
  width: 70px;
  height: 70px;
}
.page-news ul.social-share li:last-child,
.single-realizzazioni  ul.social-share li:last-child {
  width: 70px;
  height: 70px;
  cursor: pointer;
  line-height: 17px;
}
.single-realizzazioni  ul.social-share li:last-child {
  border: 1px solid #ededed;
}
.single-realizzazioni  ul.social-share li:last-child:hover {
  border: 1px solid #fff;
}
.page-news ul.social-share li a,
.single-realizzazioni  ul.social-share li a {
  width: 70px;
  height: 70px;
}
.page-news ul.social-share:hover,
.single-realizzazioni ul.social-share:hover {
  width: 350px;
  border: 1px solid #ededed;
}
.page-news ul.social-share li:last-child img,
.single-realizzazioni ul.social-share li:last-child img {

margin-top: 22px;
}
