.accordition  {
  overflow: hidden;
}
.accordition h2 {
  padding-right: 50px;
  position: relative;
  color: var(--main-darkgrey);
  font-weight: normal;
  cursor: pointer;
  }
.accordition h2::after {
  display: inline-block;
  content: "";
  padding-left: 25px;
  padding-right: 25px;
  background-image: url('images/menu-arrow-grey.svg');
  background-repeat: no-repeat;
  background-position: center center;
  width: 14px;
  height: 10px;
  vertical-align: middle;
  transform-origin: center center;
  transform: rotate(-180deg);
  transition: transform ease 0.2s;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: -25px;
}
.accordition.open h2::after {
  transform:rotate(0deg);
}
.accordition .container-accordition-body {
  overflow: hidden;
  /*ransform: scaleY(0) ;*/
  opacity: 0;
  transform-origin: 0 0;
  max-height: 0px;
  transition: all linear 0.2s ;
  visibility: hidden;
  overflow: hidden;
}
.accordition.open .container-accordition-body {
  /*transform: scaleY(1) ;*/
  opacity: 1;
  max-height: 10000px;
  visibility: visible
}
.accordition .container-accordition-body .accordition-body {
  transform: translateY(30px);
  transition: transform linear 0.1s;
}
.accordition.open .container-accordition-body .accordition-body {
  transform: translateY(0%);
  transition: transform linear 0.3s;
  padding-bottom: 40px;
}
