.page-id-83 .container > ul{
  padding-left: 0px;
}
.page-id-83 .container > ul li{
  list-style-type: none;
}
.page-id-83 .container > ul li::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: var(--main-red);
  margin-right: 55px;
  transform: rotate(-45deg);
}
.page .full-width header.container h1 {
  font-size: 89px;
  line-height: 73px;
  color: #fff;
  font-weight: bold;
  text-transform: none;
  margin-bottom:40px;
}
.page .full-width header.container h2 {
    margin: 0px 0px 10px 0px;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 13px;
}
.page .push-up {
  /*margin-top: -105px;*/
  margin-top: -75px;
}
.page .container.default-page .page-thumb {

}
.page .container.default-page .page-thumb img{
  width: 100%;
  height: auto;
}
.page .container.default-page p,
.page .container.default-page ul,
.page .container.default-page div:not(.page-thumb) {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
    color: var(--main-grey);
}
.page .container.default-page > :last-child:not(aside)   {
  /*margin-bottom:130px;*/
}
.page-news {
  position: absolute;
  top: 710px;
  right: 0px;

}
.page .page-news > ul.next-prev {
  background-color: #fff;
  box-shadow: 0px 0px 32px rgba(0,0,0,0.1);
  margin: 0px;
  padding: 0px;
  border: 1px solid #ededed;
  border-right-width: 0px;
}
.page .page-news > ul.next-prev > li {
  list-style-type: none;
}
.page .page-news > ul.next-prev > li a {
  display:block;
  width: 70px;
  height: 70px;
  padding: 20px;
}
.page .page-news > ul.next-prev > li:first-child a {
  padding: 24px;
}
.page .page-news > ul.next-prev > li:nth-child(2) a img {
  transform: rotate(180deg);
}
.page .page-news > ul.next-prev > li:not(:last-child) a {
    border-bottom: 1px solid #ededed;
}
.page .page-news > ul.next-prev > li a span {
  display: none;
}
.page .page-news > ul.next-prev  > li:last-child {
  position: relative;
  width: 70px;
  height: 70px;
}
.insertion {
  padding: 50px 0px 80px 0px;
  color: #fff;
}
.insertion.center {
  text-align: center;

}
.insertion h2 {
  font-size: 16px;
  line-height: 27px;
  text-transform: uppercase;
  letter-spacing: 1.52px;
  margin: 0px 0px 60px 0px;
  font-weight: normal;
}
.insertion h3 {
  font-size: 29px;
  line-height: 32px;
  margin: 0px 0px 8px 0px;
}
.insertion p {
  font-size: 16px;
  line-height: 28px;
  color: var(--main-grey);
  margin: 0px 0px 10px 0px;
}
.insertion .icon-container,
.insertion .icon-container .icon-pointer  {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

}
.insertion .icon-container .icon {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 240px;
}
.insertion .icon-container .icon .icon-img {
  height: 135px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 20px;
}
.insertion .icon-container .icon .icon-img img{
  object-fit: contain;

}
