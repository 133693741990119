body.preloadingPage {
  overflow:hidden;
}
body .animation-preloading {
  height: 0;
  display: none;
}
body.preloadingPage .animation-preloading {
  width: 100vw;
  height: 100vh;
  position:fixed;
  z-index: 10000;
  display:flex;
  overflow: hidden;
}
body.preloadingPage .animation-preloading .step-1,
body.preloadingPage .animation-preloading .step-2,
body.preloadingPage .animation-preloading .step-3,
body.preloadingPage .animation-preloading .step-4,
body.preloadingPage .animation-preloading .step-5,
body.preloadingPage .animation-preloading .step-6 {
  background-color: var(--main-red);
  height:100%;
  border-right: 0.5px solid #B81A1F;
  border-left: 0.5px solid #B81A1F;
  flex-shrink:1;
  flex-grow:1;
}
body.preloadingPage .animation-preloading header {
  position: absolute;
  width: 160px;
  height: 200px;
  top: calc(50% - 100px);
  left: calc(50% - 80px);
  background-image: url('images/spinner-loading.svg');
  background-repeat: no-repeat;
  background-position: center bottom;
}
body.preloadingPage .animation-preloading header svg {
  transform: translateX(5px);

}
