.page-template-page_contatti.page .container.default-page {
  max-width: 1440px;
}
.page-template-page_contatti.page .pinContatti,
.page-template-page_contatti.page .telContatti,
.page-template-page_contatti.page .faxContatti,
.page-template-page_contatti.page .mailContatti {
  position: relative;
  padding-left: 36px;
}
.page-template-page_contatti.page .hr::after {
  content: "";
  display: block;
  width: 73px;
  height: 1px;
  background-color: var(--main-darkgrey);
  margin-top: 40px;
}
.page-template-page_contatti.page .pinContatti::before,
.page-template-page_contatti.page .telContatti::before,
.page-template-page_contatti.page .faxContatti::before,
.page-template-page_contatti.page .mailContatti::before {
  content: "";
  display: block;
  position: absolute;
  width: 17px;
  height: 30px;
  background-image: url('images/pingrey-icon.svg');
  background-size: 17px auto;
  background-repeat: no-repeat;
  background-position: top left;
  top: 5px;
  left: 0px;
  opacity: 0.4;
}
.page-template-page_contatti.page .telContatti::before {
  background-image: url('images/tel-icon.svg');
  background-size: 15px auto;
}
.page-template-page_contatti.page .faxContatti::before {
  background-image: url('images/fax-icon.svg');
  top: 8px;
}
.page-template-page_contatti.page .mailContatti::before {
  background-image: url('images/mailgrey-icon.svg');
  top: 10px;
}
.page-template-page_contatti.page .container.default-page div,
.page-template-page_contatti.page .container.default-page div address,
.page-template-page_contatti.page .container.default-page div p.pinContatti,
.page-template-page_contatti.page .container.default-page div p.telContatti,
.page-template-page_contatti.page .container.default-page div p.faxContatti,
.page-template-page_contatti.page .container.default-page div p.mailContatti {
  max-width: none;
  font-size: 20px;
  line-height: 34px;
  color: var(--main-darkgrey);
  font-style: normal;
}
p.telContatti,
p.faxContatti {
  margin-bottom: 0px;
  margin-top: 0px;
}
.page-template-page_contatti.page .container.default-page div address {
  margin-bottom: 50px;
}
.page-template-page_contatti .full-width header.container h1 {
  font-size: 57px;
  line-height: 73px;
}
.page-template-page_contatti .wp-block-columns.has-2-columns {
  display: flex;
}
.page-template-page_contatti .wp-block-columns.has-2-columns .wp-block-column{
  flex-basis: 50%;
  flex-shrink: 1;
  flex-grow: 1;

}
.page-template-page_contatti .wp-block-columns.has-2-columns .wp-block-column:nth-child(1) {
  padding-left: 240px;
}
.page-template-page_contatti .wp-block-columns.has-2-columns .wp-block-column:nth-child(2) {
  line-height: 0;
}
.page-template-page_contatti .wp-block-columns.has-2-columns .wp-block-column h3 {
  font-size: 39px;
  line-height: 47px;
  color: #000;
  margin-top:180px;
}
.page-template-page_contatti.page .container.default-page #googleMaps {
  height: 713px;
  width: 100%
}
